
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background: url('assets/Background.png');
  background-size: cover;

}
.app{
  height: 100%;
  background-image: url('../src/assets/Group 5478.png');
  background-size: cover;
  display: flex;
  width: 100%;
}

*::-webkit-scrollbar {
  display: none;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

:focus{
  outline: none !important;
}


p{
  margin-bottom: 0 !important;
}

.css-df17o1 {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
}