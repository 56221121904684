  
*{
  /* font-family: 'Raleway Medium'; */
  transition: .2s;
}

@font-face {
 font-family: 'Raleway Medium';
 src: url("assets/fonts/Raleway-Medium.ttf"); 
 font-weight: normal; 
}

@font-face {
 font-family: 'Raleway Bold';
 src: url("assets/fonts/Raleway-Bold.ttf");
}
@font-face {
  font-family: 'Raleway Light';
  src: url("assets/fonts/Raleway-Light.ttf");
 }
 @font-face {
  font-family: 'Raleway ExtraBold';
  src: url("assets/fonts/Raleway-ExtraBold.ttf");
 }

/* @font-face {
 font-family: 'Futura Bold Condensed BT';
 src: url("assets/fonts/Futura Bold font.ttf");
} */
.raleway_bold{
  font-family: 'Raleway Bold' !important;
}
.bord{
  border:1px solid white;
}
.trans-button{
  background-color: transparent;
  border: none;
}

.modal-content{
  background-color: #222433 !important;
}

.modal-body{
 padding: 35px !important;
}
.modal-head{
 color: white;
 font-size: 25px;
 font-family: 'Raleway Bold' !important;
}
.modal_invite-content{
  width: 70% !important;
  border-radius: 41px !important;
}
.form-custom-input{
  background-color: #292B3A !important;
  border: 2px solid #3C3E4F !important;
  border-radius: 29px !important;
  width: 100%;
  /* padding-left: 20px !important; */
  color: white !important;
  font-size: 14px !important;
  padding: 8px 8px 8px 20px !important;
  font-weight: 600 !important;
}
.custom_label{
  color: #a9abb8;
  font-size: 14px;
  font-family: 'Raleway medium';
}
.modal_send-btn{
  border: none;
    background-image: linear-gradient(to right, #B3566F , #5351BF);
    color: white !important;
    text-transform: uppercase;
    border-radius: 24px !important;
    width: 100%;
    padding: 10px !important;
    font-family: 'Raleway Bold';
    font-size: 13px !important;
    margin-right: 5px;
    margin-top: 8px !important;
    border-color: transparent !important;
    line-height: 100% !important;
}
.modal_cancel-btn{
  border: none;
    background-color: #1E1D2B;
    color: white;
    text-transform: uppercase;
    border-radius: 24px;
    width: 100%;
    padding: 10px;
    font-family: 'Raleway Bold';
    font-size: 13px;
    margin-left: 5px;
    margin-top: 8px;
}
.modal_close{
  width: 46px;
  border: none !important;
  position: absolute;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABHNCSVQICAgIfAhkiAAAAlxJREFUSEvFlz1200AQx//jMhAjWpBMqAC5wDcIR4CG0GGfIOYEhBPgnCCmIzTADcwNnEICqvh59ahlGVJ6eCN585y1PlaOCSq1M/PbmfnvF8Hy87wnz4HGPhgdAjsg6qSuzGMGxSCMAR4pFX61CUllRo6z5zRv7xyCqA/AsQkIIAbzIPl9cRzHk7jIpxDs3fe71KD3NYAmI2Ysekp9/5IHzwW7bnvQIBxaZlhqxsBAqeCNabQG9rz2kIDX24DqGAweKhX2VmNeAW8zU3PiC8ZxFAWilfS7BC97erLNTM1YjMUL3fMUnKp399b5NYRkO984mf95KGpPwS3XPwLRW1vva9kxv5tG4VEG9vwYoDtlAX3/EaLoF5JknmvWbO7Cde8hDH9WzSuequAuyY5EaHyugn48PUnBrw56a3CByriAZbwKLr0mGyVL4NNPQ0jWQfDjClxD2+3HKfDgZbewKjo5UTh5rj8iov2q+uTBxUcyrQMVH2b+Ri3Xn4DoQRVYxk24/KsLTTnMY2p5bbaBaptVuPyzLa/J2AisyyvBzJ7bJlG71Ks9FUie4KrgzDirJS5TSAIoUnsZPBWX7XIqUm/ZUiuCZ8vJcgORzGQDyVunGi4biIxbbSA3v2XybKpC5/8eEtmxuDOpOiiq1Fo9zrNkfrF3eSyKg02vqwOXW6xdBLS5jcI3hi/PYe1/Q5c9fFAq6K5O+p9fb2FkWpixHsh6TsPNBcczBndrXeg1fPmE6YPQt58Az8CQJ8xgoyeMKSKpAHPjGYE7ADlEeJodrTgDOGbQmGgxKsrQjPcXUBFp8djnrCYAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-size: 20px;
  color: transparent !important;
  opacity: 1 !important;
  z-index: 999999;
  right: 0px;
  top: 10px;
  text-shadow: none !important;
}
.user_table tr{
  border-bottom: 1px solid #3f414d;
}
.user_table{
  width: 100%;
  background: transparent;   
}
.user_table tr:nth-child(even){
  background-color: #1E1D2B;
}
.user_table th{
  color: #979797;
  font-family: 'Raleway Bold';
  font-size: 11px;
  padding: 20px 20px 10px 20px;
}
/* .user_table th:nth-child(1){
  padding-left: 62px !important;
} */
.user_table td{
  color: white;
  font-family: 'Raleway Bold';
  font-size: 13px;
  padding: 10px 20px 10px 20px;
}
.table-user-img{
  width: 35px;
  height: 35px;
  margin-right:10px;
  margin-left:5px;
  object-fit: cover;
  border-radius: 17.5px;
}
.action_btn{
  width: 25px;
  cursor: pointer;
}


/* toggle button */

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 24px;
  margin: 0;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 2.5px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #3F414D;
}

input:focus + .slider {
  box-shadow: 0 0 1px #3F414D;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}

.invite_button{
  border: none;
  background-image: linear-gradient(to right, #B3566F , #5351BF);
  color: white;
  text-transform: uppercase;
  border-radius: 24px;
  padding: 10px 28px;
  font-family: 'Raleway Bold';
  font-size: 13px;
}
.com_management_modal-body{
  padding: 20px !important;
  border-radius: 20px !important;
}
.modal_headtext{
  color: white;
  font-family: 'Raleway ExtraBold';
  font-size: 23px;
}
.modal_smalltext{
  color: white;
    font-family: 'Raleway ExtraBold';
    font-size: 15px;
    padding-top: 13px;
}
.modal_form{
 display: block;
}
.modal_form label{
  color: #8D8D8D;
  font-weight: 600;
  font-size: 13px;
  font-family: 'Raleway ExtraBold';
  margin-top: 10px;
}
.modal_form input{
 border: none;
 background-color: #ECECEC;
 border-radius: 51px;
 padding:8px 20px;
 color: black;
 font-size: 14px;
 display: block;
 font-family: 'Raleway ExtraBold';
}
.modal_form button{
  padding: 7px 28px !important;
}



.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  background-color: transparent !important;
  border-color: none;
  color: white !important;
  font-family: 'Raleway Bold';
  transform: scale(1.10);
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: none !important;
  color: #8D8D8D !important;
  font-family: 'Raleway Bold';
  cursor: pointer;
}
.nav-tabs {
  border-bottom: none !important;
}
.upt_dropdown{
  display: inline-block !important;
}
.dropdown-menu{
  background-color: transparent !important;
}
.statistic_div{
  background-color: #3B3D4B;
  padding: 10px;
  border-radius: 5px;
}
.sta-head{
  font-size: 14px !important;
  color: white !important;
  font-weight: 100 !important;
  padding-bottom: 7px !important;
  font-family: 'Raleway Medium' !important;
}
.sta-inner_div{
  background-color: #222433; 
  padding: 8px 10px;
  border-radius: 10px;
  width: 130px;
  margin-right: 8px;
}
.sta-inner_div p:nth-child(1){
  font-size: 10px !important;
  font-family: 'Raleway medium';
  color: grey !important;
  padding:0 !important;
  font-weight: 100 !important;
}
.sta-inner_div p:nth-child(2){
  font-size: 14px !important;
  font-family: 'Raleway medium';
  color: white !important;
  margin-top: -4px;
  font-weight: 100 !important;
}


.commison_popup{
  background: white !important;
  border-radius: 20px !important;
  border: 2px solid white !important;
}

.commision_btn{
  right: 20px;
}
.tabcontent_referal{
  overflow: auto;
  height: 77vh;
  bottom: 20px;
}
.nav-tabs .nav-item:first-child .nav-link{
  padding-left: 0;
}
._loading_overlay_overlay{
  position: fixed !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
/* 
_loading_overlay_content
_loading_overlay_spinner */
.cust-radio{
  width: 25px;
  height: 25px;
}
.radio-label{
  font-size: 15px;
  margin-left: 15px;
  margin-right: 15px;
  font-family: 'Raleway ExtraBold';
  font-weight: 600;
}

ul.ant-pagination {
  margin-top: 1rem;
}